@import "../../../public/style/variables";

.header-wrap-small{
  height: 80px;
  position: relative;
  @include breakpoint( max , 1024){
    height: 53px;
  }
  header{
    background: $header_bg_color;
    position: fixed;
		width: 100%;
		top: 0;
		left: 0;
    padding: 10px;
    z-index: 123;
    .logo{
      padding-right: 30px;
      text-align: center;
      @include breakpoint( max , 767){
        padding-right: 10px;
      }
      a{
        display: inline-flex;
        align-items: center;
        .logo-icon{
          flex-shrink: 0;
          margin-right: 16px;
          @include breakpoint( max , 1024){
            margin-right: 10px;
          }
          svg{
            width: 58px;
            height: 58px;
            @include breakpoint( max , 1024){
              width: 30px;
              height: 30px;
            }
          }
        }
        .logo-text{
          color: $color_white;
          font-size: 28px;
          font-weight: 600;
          text-transform: uppercase;
          @include breakpoint( max , 1024){
            font-size: 22px;
          }
        }
      }
    }
  }
}