.btn{
	font-size: 14px;
	line-height: 17px;
	padding: 5px 24px 6px;
	@include border-radius(3px);
	&:focus,
	&:active{
		box-shadow: none !important;
	}
	&.rounded{
		@include border-radius(18px !important);
	}
	&.outline{
		background: transparent !important;
	}
	&.btn-white{
		background: $color_white;
		border-color: $color_white;
		color: $secondary_color;
	}
	&.btn-gray {
		background: $gray_color;
		border-color: $gray_color;
		color: $color_white;
	}
	&.btn-bold{
		font-weight: 700;
		font-size: 16px;
		text-transform: uppercase;
		padding: 6px 18px 5px;
	}
	&.btn-lg{
		padding: 11px 18px 10px;
	}
	&.btn-icon {
		width: 33px;
		height: 30px;
		padding: 0;
		display: inline-flex;
		align-items: center;
		justify-content: center;
	}
	&.btn-normal-link{
		padding: 0;
		border: 0;
		box-shadow: none;
		border-radius: 0;
		color: $color_white;
		font-size: 14px;
		line-height: 17px;
		&.dropdown-toggle{
			display: block;
			&:after{
				border: 0;
				width: 9px;
				height: 5px;
				vertical-align: middle;
				margin-left: 5px;
				background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.092' height='5' viewBox='0 0 8.092 5'%3E%3Cpath id='down-arrow-blue' d='M49.861,0l-.954.954L52,4.046,48.907,7.138l.954.954,4.046-4.046Z' transform='translate(8.092 -48.907) rotate(90)' fill='%23228cb7'/%3E%3C/svg%3E%0A") no-repeat;
				background-size: cover;
			}
		}
	}
	&.append-icon{
		.icon{
			display: inline-block;
			border-left: 1px solid $color_white;
			margin-left: 7px;
			padding-left: 7px;
			vertical-align: -2px;
			svg{
				display: block;
			}
		}
	}
	&.prepend-icon{
		display: inline-flex;
		align-items: center;
		.icon{
			display: inline-block;
			margin-right: 5px;
			vertical-align: middle;
			svg{
				display: block;
			}
		}
	}
	&.btn-outline-primary{
		.icon{
			svg{
				path{
					fill: $primary_color;
				}
			}
		}
		&:hover{
			.icon{
				svg{
					path{
						fill: $color_white;
					}
				}
			}	
		}
	}
	&.btn-primary{
		.icon{
			svg{
				path{
					fill:  $color_white;
				}
			}
		}
	}
	&.btn-modal-close{
		padding: 0;
		border: 1px solid $primary_color;
		background: $color_white;
		width: 26px;
		height: 26px;
		display: flex;
		align-items: center;
		justify-content: center;
		@include border-radius(100%);
		svg{
			display: block;
		}
	}
}

.cta-group{
	.btn + .btn{
		margin-left: 10px;
	}
}

.login-footer{
	text-align: center;
	font-size: 12px;
	padding-top: 14px;
	color: $header_bg_color;
}

.nc-tooltip {
	&.tooltip {
		font-family: $primary_font;
		&.show {
			opacity: 1;
		}
		.tooltip-inner {
			border: 1px solid $color_firefly;
			background: $color_firefly;
			color: $color_concrete;
			text-align: left;
			padding: 9px;
			max-width: 230px;
			@include border-radius(5px);
			@include breakpoint(max, 1270) {
				max-width: 300px;
			}
		}
		&.bs-tooltip-left .arrow::before,
		&.bs-tooltip-auto[x-placement^="left"] .arrow::before {
			border-left-color: $color_firefly;
		}
		&.bs-tooltip-bottom .arrow::before,
		&.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
			border-bottom-color: $color_firefly;
		}
		&.bs-tooltip-right .arrow::before,
		&.bs-tooltip-auto[x-placement^="right"] .arrow::before {
			border-right-color: $color_firefly;
		}
		&.bs-tooltip-top .arrow::before,
		&.bs-tooltip-auto[x-placement^="top"] .arrow::before {
			border-top-color: $color_firefly;
		}
	}
	font-size: 12px;
	font-weight: 600;
	line-height: 14px;
	color: $color_concrete;
}

.community-badge{
	width: 30px;
	height: 30px;
	font-size: 16px;
	font-weight: 600;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: $color_cerulean_blue;
	color: $color_white;
	text-transform: capitalize;
	@include border-radius(3px);
	&-public{
		background: $color_cerulean_blue;
	}
	&-private{
		background: $color_gigas;
	}
	&-restricted{
		background: $color_black;
	}
}

.article-rate-badge{
	width: 30px;
	height: 30px;
	font-size: 16px;
	font-weight: 600;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: $mercury;
	color: $color_white;
	@include border-radius(3px);
	&-good{
		background: $color_green;
	}
	&-average{
		background: $color_yellow;
	}
	&-bad{
		background: $color_red;
	}
	&.large-badge{
		width: 40px;
		height: 40px;
		font-size: 18px;
	}
}

.comment-actions{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	> div + div{
		margin-left: 10px;
	}
	.active{
		// color:$primary_color !important;
		svg {
			path{
				fill: $primary_color  !important;
			}
		}
	}
	.like-dislike{
		display: flex;
		align-items: center;
		font-size: 12px;
		line-height: 10px;
		font-weight: 600;
		svg{
			display: block;
		}
		> div{
			display: flex;
			align-items: center;
		}
		.like{
			margin-right: 6px;
			padding-right: 6px;
			border-right: 1px solid rgba($gray_color, .5);
			svg{
				margin-left: 7px;
			}
		}
		.dislike{
			svg{
				margin-right: 7px;
			}
		}
	}
	.comment-reply, 
	.comment-report,
	.comment-count {
		font-size: 12px;
		display: flex;
		align-items: center;
		// cursor: pointer;
		.icon{
			margin-right: 5px;
		}
		.no{
			margin-right: 5px;
		}
		.txt{
			@include breakpoint( max , 767){
				display: none;
			}
		}
		svg{
			display: block;
		}
	}
	.comment-report{
		.active{
			svg {
				path {
					fill: red !important;
				}
			}
		}
	}
	.comment-share{
		a{
			.txt{
				padding-left: 5px;
				font-size: 12px;
			}	
		}
		.btn{
			&.btn-share{
				border: 0;
				padding: 0;
				color: $gray_color;
				font-size: 12px;
				line-height: 12px;
				display: flex;
				.icon{
					margin-right: 5px;
				}
				.txt{
					@include breakpoint( max , 767){
						display: none;
					}
				}
				&:after{
					display: none;
				}
			}
		}
		.txt {
			@include breakpoint(max, 767) {
				display: none;
			}
		}
		.dropdown-menu{
			border: 0;
			padding: 0;
			-webkit-box-shadow: 0px 2px 3px rgba(0, 0, 0, .16);
			-moz-box-shadow: 0px 2px 3px rgba(0, 0, 0, .16);
			box-shadow: 0px 2px 3px rgba(0, 0, 0, .16);
			@include border-radius(3px);
			overflow: hidden;
			min-width: 100px;
			a, div{
				display: flex;
				align-items: center;
				width: 100%;
				padding: 6px 8px;
				color: $color_gray_dark;
				font-size: 12px;
				white-space: nowrap;
				cursor: pointer;
				@include transition(all 0.3s ease-in-out);
				&:hover{
					background: $color_concrete;
				}
				.icon{
					padding-right: 8px;
					svg{
						display: block;
					}
				}
			}
		}
	}
}

.comment-form{
	> label{
		font-size: 12px;
		color: $color_gray_dark;
		display: block;
		margin-bottom: 6px;
	}
	.comment-form-box{
		border: 1px solid $color_mercury2;
		@include border-radius(5px);
		.form-control{
			border: 0;
			box-shadow: none;
			height: 133px;
			font-size: 14px;
			color: $gray_color;
			padding: 13px 16px;
			@include placeholder{color: $gray_color;};
			@include border-radius(5px 5px 0 0);
		}
		.comment-form-footer{
			background: $color_athens_gray;
			padding: 4px 8px;
			text-align: right;
			@include border-radius(0 0 5px 5px);
			.cta{
				display: inline-flex;
				flex-wrap: wrap;
				justify-content: flex-end;
			}
		}
	}
}

.rating{
	display: flex !important;
	> span + span{
		margin-left: 8px;
	}
	span{
		svg{
			display: block;
		}
	}
}

.change-password-popup{
	.modal-dialog{
		max-width: 400px;
	}
	.cta-group{
		padding-top: 8px;
	}
}

.page-title{
	font-size: 36px;
	line-height: 44px;
	font-weight: 700;
	color: $secondary_color;
	padding-top: 35px;
	@include breakpoint( max , 767){
		font-size: 28px;
		line-height: 34px;
	}
}

.pagination-wrap{
	ul{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		li{
			padding: 0 5px 5px;
			&.prev,
			&.next{
				a{
					width: 52px;
					path{
						fill: currentColor;
					}
				}
			}
			a, span{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 30px;
				height: 30px;
				color: $gray_color;
				font-size: 14px;
				line-height: 1;
				font-weight: 500;
				background: $color_white;
				border: 1px solid $color_mercury2;
				@include border-radius(5px);
				@include transition(all 0.3s ease-in-out);
				&:hover{
					color: $color_white;
					background: $primary_color;
					border-color: $primary_color;
				}
			}
			&.active{
				a, span{
					color: $color_white;
					background: $primary_color;
					border-color: $primary_color;
				}
			}
			&.ellipsis{
				a, span{
					background: transparent;
					border-color: transparent;
				}
			}
		}
	}
}