@import "../../../public/style/variables";

.header-wrap{
	position: relative;
	height: 50px;
	header{
		position: fixed;
		width: 100%;
		height: 50px;
		top: 0;
		left: 0;
		padding: 7px 30px;
		background-color: $header_bg_color;
		display: flex;
		z-index: 1234;
		@include breakpoint( max , 767){
			padding: 7px 15px;
		}
	}
	.logo{
		padding-right: 30px;
		@include breakpoint( max , 767){
			padding-right: 10px;
		}
		a{
			display: flex;
			align-items: center;
			.logo-icon{
				flex-shrink: 0;
				margin-right: 10px;
				@include breakpoint( max , 1024){
					margin-right: 0;
				}
				svg{
					width: 36px;
					height: 36px;
				}
			}
			.logo-text{
				color: $color_white;
				font-size: 22px;
				font-weight: 600;
				text-transform: uppercase;
				@include breakpoint( max , 1024){
					display: none;
				}
			}
		}
	}
	.header-search{
		position: relative;
		@include breakpoint( max , 767){
			position: inherit;
		}
		@include breakpoint-between( 768 , 991){
			position: inherit;
		}
		.btn-search{
			display: none;
			@include breakpoint( max , 767){
				padding: 0;
				width: 36px;
				height: 36px;
				display: none;
				align-items: center;
				justify-content: center;
			}
		}
		.form-group{
			margin: 0;
			position: relative;
			.icon{
				position: absolute;
				left: 10px;
				top: 11px;
				svg{
					display: block;
				}
			}
			.form-control{
				height: 36px;
				border: 0;
				background: $color_mercury2;
				box-shadow: none;
				padding-left: 34px;
				color: $gray_color;
				font-size: 14px;
				font-weight: 300;
				@include border-radius(18px);
				@include placeholder{color: $gray_color;};
			}
		}
		.search-area{
			@include breakpoint( max , 767){
				// position: absolute;
				// top: 100%;
				// left: 0;
				// width: 100%;
			}
			form{
				@include breakpoint( max , 767){
					// padding: 8px 16px;
					// background-color: $header_bg_color;
					// border-top: 1px solid rgba($gray_color, .3);
				}
			}
			.collapse{
				display: block;
			}
			
		}
		@include breakpoint( min , 768){
			.collapse{
				display: block;
			}
		}
		.search-dd{
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			background: $color_white;
			max-width: 576px;
			margin: 7px auto 0;
			border: 1px solid $body_bg_color;
			display: inline-block;
			@include box-shadow(0px 3px 6px rgba(13, 41, 52, .10));
			@include breakpoint-between( 768 , 991){
				margin-top: 0;
			}
			@include breakpoint( max , 767){
				margin-top: 0;
				width: 90%;
			}
			.search-dd-list{
				ul{
					padding: 8px 0;
					max-height: 300px;
					overflow: auto;
					li{
						padding: 8px 15px;
						display: flex;
						.avatar{
							flex-shrink: 0;
							width: 40px;
							margin-right: 10px;
							img{
								width: 40px;
								height: 40px;
								@include border-radius(100%);
							}
						}
						.info{
							.search__title{
								font-size: 16px;
								line-height: 19px;
								font-weight: 600;
								color: $label_color;
								a{
									display: inline-block;
									color: $label_color;
								}
							}
							.search__sub-title{
								font-size: 14px;
								color: $gray_color;
							}
						}
					}
				}
			}
		}
	}
	.menu{
		@include breakpoint( max , 767){
			display: none;
		}
		> ul{
			display: flex;
			> li{
				margin-right: 28px;
        &.home-link{
          @include breakpoint( max , 1024){
            display: none;
          }
        }
				> a{
					display: block;
					color: $color_white;
					font-size: 14px;
					line-height: 17px;
					&.active{
						font-weight: 700;
					}
				}
				.dropdown-menu{
					max-height: 210px;
					overflow: auto;
				}
			}
		}
	}
	.header-left{
		flex-shrink: 0;
		display: flex;
		align-items: center;
	}
	.header-center{
		flex-shrink: 1;
		width: 100%;
		padding-right: 30px;
		@include breakpoint( max , 767){
			padding-right: 16px;
			padding-left: 6px;
			// width: auto;
		}
	}
	.header-right{
		flex-shrink: 0;
		display: flex;
		align-items: center;
		@include breakpoint( max , 767){
			margin-left: auto;
		}
		.help-link{
			@include breakpoint( max , 767){
				display: none;
			}
			a{
				color: $color_white;
				font-size: 14px;
				line-height: 17px;
				svg{
					margin-right: 1px;
				}
			}
		}
		.notification-icon{
			padding-left: 13px;
			@include breakpoint( max , 767){
				padding-left: 0;
				padding-right: 14px;
			}
			a{
				display: block;
				svg{
					display: block;
				}
			}
		}
		.cta{
			position: relative;
			margin-left: 12px;
			padding-left: 24px;
			&:after{
				content: "";
				width: 1px;
				height: 20px;
				position: absolute;
				left: 0;
				top: 50%;
				margin-top: -10px;
				background: rgba($gray_color, .3);
			}
			@include breakpoint( max , 767){
				display: none;
			}
			.btn + .btn{
				margin-left: 7px;
			}
		}
	}
}

.header--user--dropdown{
  .btn-normal-link{
    display: flex !important;
    align-items: center;
	text-transform: capitalize !important;
  }
  .auth-photo{
    width: 36px;
    flex-shrink: 0;
    margin-right: 8px;
    img{
      @include border-radius(100%);
    }
  }
}

.notification-icon{
	.dropdown-toggle{
		&:after{
			display: none;
		}
		padding: 0 10px !important;
	}
	.dropdown-menu{
		border: 0;
		width: 500px;
		margin-top: 14px;
		@include box-shadow(0px 3px 6px rgba(0, 0, 0, .12));
		@include breakpoint( max , 767){
			width: 300px;
		}
		&:before{
			content: "";
			width: 0; 
			height: 0; 
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			border-bottom: 7px solid $color_white;
			position: absolute;
			right: 10px;
			top: -7px;
			// @include breakpoint( max , 767){
			// 	display: none;
			// }
		}
	}
	.notifications-list{
		max-height: 400px;
		overflow: auto;
		@include breakpoint( max , 767){
			max-height: 200px;
			overflow: auto;
		}
		ul{
			li{
				.notifications-info{
					@include breakpoint( max , 767){
						font-size: 13px;
					}
				}
			}
		}
	}
	.notifications-dd-header{
		padding: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid $color_fantasy;
		@include breakpoint( max , 767){
			padding: 8px 16px;
		}
		h4{
			font-size: 20px;
			font-weight: 600;
			color: $heading_color;
			@include breakpoint( max , 767){
				font-size: 16px;
			}
		}
		.view-all-notification{
			a.dropdown-item{
				display: inline-block;
				color: $primary_color;
				font-size: 13px;
				line-height: 1;
				border-bottom: 1px solid $primary_color;
				background: transparent;
				padding: 0;
			}
		}
	}
}