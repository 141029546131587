@import "../../../public/style/variables";

.mobile-menu {
  display: none;
  @include breakpoint(max, 767) {
    display: block;
  }
  .menu-btn {
    button {
      width: 20px;
      height: 20px;
      background: transparent;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        display: block;
      }
    }
  }
}

.mobile-menu-wrap {
  position: fixed;
  top: 0;
  right: -100%;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: $header_bg_color;
  @include transition(all 0.3s ease-in-out);
  &.show-menu,
  &.show-sub-menu {
    right: 0;
  }
  .m-top {
    overflow: auto;
  }
  .m-footer {
    margin-top: auto;
    padding: 16px 16px 0;
    .login-footer {
      color: $color_regent_gray;
      padding: 16px 0;
      font-size: 14px;
      text-align: left;
    }
  }
  .mobile-menu-header {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .mobile-logo {
      a {
        display: flex;
        align-items: center;
        .logo-icon {
          flex-shrink: 0;
          margin-right: 10px;
          svg {
            width: 36px;
            height: 36px;
          }
        }
        .logo-text {
          color: $color_white;
          font-size: 22px;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }
  }
  .user-name-info {
    background: $color_elephant;
    padding: 13px 16px;
    display: flex;
    align-items: center;
    .photo {
      width: 30px;
      margin-right: 8px;
      img {
        max-width: 30px;
        @include border-radius(100%);
      }
    }
    .name {
      font-size: 18px;
      line-height: 23px;
      color: $color_white;
      text-transform: capitalize !important;
    }
  }
  .communities-menu-header {
    padding: 13px 16px 4px;
    .back {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 22px;
      line-height: 1;
      font-weight: 600;
      color: $color_regent_gray;
      letter-spacing: 0.002em;
      svg {
        display: block;
        margin-right: 8px;
      }
    }
    .back-box {
      border-bottom: 1px solid rgba($color_regent_gray, 0.3);
      padding-bottom: 16px;
    }
  }
  .search-mobile-communities {
    padding-top: 10px;
    .form-group {
      margin: 0;
      position: relative;
      .icon {
        position: absolute;
        left: 10px;
        top: 11px;
        svg {
          display: block;
        }
      }
      .form-control {
        height: 36px;
        border: 0;
        background: $color_mercury2;
        box-shadow: none;
        padding-left: 34px;
        color: $gray_color;
        font-size: 14px;
        font-weight: 300;
        @include border-radius(18px);
        @include placeholder {
          color: $gray_color;
        }
      }
    }
  }
}

.mobile-menu-list {
  padding-top: 4px;
  ul {
    li {
      .menulink {
        display: flex;
        align-items: center;
        padding: 11px 16px;
        font-size: 18px;
        line-height: 23px;
        color: $color_white;
        cursor: pointer;
        .icon {
          width: 30px;
          svg {
            display: block;
          }
        }
        .next-arrow {
          margin-left: auto;
          svg {
            display: block;
            width: 13px;
            height: 8px;
            @include transform-rotate(-90deg);
          }
        }
      }
    }
  }
}

.search-communities-list {
  overflow: auto;
  ul {
    li {
      a {
        display: flex;
        align-items: center;
        padding: 12px 16px;
        font-size: 18px;
        line-height: 23px;
        color: $color_white;
        .avatar {
          width: 26px;
          margin-right: 12px;
          flex-shrink: 0;
          img {
            @include border-radius(100%);
          }
        }
      }
    }
  }
}

.menu-quick-links {
  overflow: auto;
  ul {
    li {
      a {
        display: block;
        padding: 11px 16px;
        font-size: 18px;
        line-height: 23px;
        color: $color_white;
      }
    }
  }
}
