// ================================================================================================
// 	Version: 1.0
// ================================================================================================
html {
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    height: 100%;
}

input[type="submit"] {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    // cursor: none;
    &:after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    &:before {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }
}

:focus {
    outline: 0;
}

body {
    margin: 0;
    padding: 0;
    font-family: $primary_font;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: $gray_color;
    background: $body_bg_color;    
}

a {
    outline: none;
    text-decoration: none;
    color: $gray_color;
    &:hover {
        text-decoration: none;
        outline: none;
        outline-offset: 0;
        color:$heading_color;
    }
    &:focus {
        text-decoration: none;
        outline: none;
        outline-offset: 0;
        color:$heading_color;
    }
}

img {
    border: 0;
    max-width: 100%;
    height: auto;
}

input {
    outline: none;
    resize: none;
    box-shadow: none;
    font-family: $primary_font;
}
input[type="search"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

textarea {
    outline: none;
    resize: none;
    box-shadow: none;
    font-family: $primary_font;
}

select {
    outline: none;
    resize: none;
    box-shadow: none;
    font-family: $primary_font;
}

button {
    box-shadow: none;
    cursor: pointer;
    &::-moz-focus-inner {
        border: 0;
    }
}

strong,
b {
    font-weight: 600;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.35;
    margin: 0;
    font-family: $primary_font;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

p {
    margin: 0 0 7px 0;
    padding: 0;
}
button:focus {
    outline: none;
}

*::-moz-selection {
    background-color: rgba($primary_color, 1);
    color: $color_white;
}
*::selection {
    background-color: rgba($primary_color, 1);
    color: $color_white;
}
.svg {
    path {
        @include transition(all 0.3s ease-in-out);
    }
}
.container {
    max-width: 1530px;
    margin: 0 auto;
    padding: 0 15px;
    width: 100%;
    &.container-sm{
        max-width: 930px;
    }
}

.row{
    margin: 0 -12px;
    > [class^=col-] {
        padding-left: 12px;
        padding-right: 12px;
	}
	&.gutter-10{
		margin: 0 -5px;
		> [class^=col-] {
			padding-left: 5px;
			padding-right: 5px;
		}
	}
	&.gutter-16{
		margin: 0 -8px;
		> [class^=col-] {
			padding-left: 8px;
			padding-right: 8px;
		}
	}
}

.modal {
	z-index: 2050;
	.btn-modal-close{
		position: absolute;
		right: -13px;
		top: -13px;
		@include breakpoint( max , 767){
			right: 5px;
			top: 5px;
		}
	}
	.modal-header{
		text-align: center;
		display: block;
		padding: 20px 15px 17px;
		border-color: $color_concrete;
		.modal-title{
			font-size: 20px;
			line-height: 24px;
			font-weight: 700;
			color: $primary_color;
			text-transform: uppercase;
		}
	}
	.modal-content{
		border: 1px solid $color_mercury2;
		@include border-radius(5px);
	}
	.modal-body{
		padding: 24px;
		@include breakpoint( max , 767){
			padding: 20px 15px;
		}
	}
	.modal-footer{
		justify-content: center;
		padding: 20px 10px;
		border-color: $color_mercury2;
		> *{
			margin: 0
		}
	}
	&.fade{
		.modal-dialog{
			@include transform(scale(.95));
		}
		&.show{
			.modal-dialog{
				@include transform(scale(1));
			}
		}
	}
}
.modal-backdrop {
    z-index: 2040;
}

.page-loader{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(10, 22, 27, .85);
	text-align: center;
	color: rgba($color_white, .9);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	z-index: 1234;
	span{
		display: block;
	}
}